import React, { useCallback, useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { withStyles } from 'tss-react/mui';
import Chisholm from '@common/config/Chisholm';
import ApiService from '@common/services/ApiService';
import { DefaultModal } from '@common/components/default-modal';
import { DefaultButton } from '@common/components/default-button';
import WaveSelectField from '@common/components/form/WaveSelectField';
import WaveTextArea from '@common/components/form/WaveTextArea';
import WaveTextField from '@common/components/form/WaveTextField';
import { DefaultCheckbox } from '@common/components/default-checkbox';
import WaveMultipleFileUploader from '@common/components/form/WaveMultipleFileUploader';
import { transformEvidences } from '@common/services/PointService';
import { modalStyles } from './styles';
import { MAX_FILE_SIZE } from '@common/constants';
import { WaveDatePicker } from '@common/components/WaveDatePicker';
import VcmUnitPicker from './VcmUnitPicker';

const EditModal = ({
  classes,
  domainOptions,
  activityIntervalOptions,
  pointsFileAttachmentEnabled,
  isOpened,
  onClose,
  onRefreshRequest,
  pointRequest,
  vocationalActivitiesOptions,
  user,
  vocationalCurrencyUsesUnits,
  vcmUnits
}) => {
  const api = new ApiService();
  const { id, type, type_label } = pointRequest || {};

  const [data, setData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setData((d) => {
        let newPoint = d.points;

        if (name === 'vocational_activity_id') {
          newPoint = vocationalActivitiesOptions[value].points;
        }

        return {
          ...d,
          points: newPoint,
          [name]: value
        };
      });
    },
    [setData]
  );

  const handleArrayInputChange = useCallback(
    (name) => (value) => {
      setData({ ...data, [name]: value });
    },
    [setData, data]
  );

  const handleDateChange = useCallback(
    (name) => (date) => {
      setData({ ...data, [name]: date });
    },
    [setData, data]
  );

  const handlePush = useCallback(
    ({ name, value }) => {
      setData((d) => ({
        ...d,
        [name]: d[name].concat(value)
      }));
    },
    [setData]
  );

  const handleRemove = useCallback(
    ({ name, index }) => {
      setData((d) => {
        const array = d[name].slice();
        array.splice(index, 1);

        return {
          ...d,
          [name]: array
        };
      });
    },
    [setData]
  );

  const handleReplace = useCallback(
    ({ index, name, value }) => {
      setData((d) => {
        const array = d[name].slice();
        array.splice(index, 1, value);

        return {
          ...d,
          [name]: array
        };
      });
    },
    [setData]
  );

  const errorStatus = useCallback(
    (field) => {
      return validationErrors && validationErrors.hasOwnProperty(field);
    },
    [validationErrors]
  );

  const errorHelpers = useCallback(
    (field) => {
      return errorStatus(field) ? validationErrors[field][0] : null;
    },
    [errorStatus, validationErrors]
  );

  const makeFieldProps = useCallback(
    (field, fieldValue, errorField) => {
      return {
        error: errorStatus(errorField || field),
        helperText: errorHelpers(errorField || field),
        name: field,
        value: fieldValue
      };
    },
    [errorStatus, errorHelpers]
  );

  const setErrors = useCallback(
    (json) => {
      if (json.code == 1) {
        setErrorMessage(json.message);
      } else if (json.code == 2) {
        setValidationErrors(json.messages);
      }
    },
    [setErrorMessage, setValidationErrors]
  );

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);

    const { evidences, unit_codes, ...rest } = data;
    let dataToUpdate = rest;
    
    if(vocationalCurrencyUsesUnits && unit_codes){
      dataToUpdate.unit_codes = unit_codes;
    }

    try {
      const url = `/api/v1/users/${user.id}/point_requests/${id}`;
      const res = await api.put(url, {
        point_request: {
          ...dataToUpdate,
          evidences_attributes: transformEvidences(evidences)
        }
      });

      if (res.status === 200) {
        handleClose();
        onRefreshRequest();
      } else if (res.status === 422 || res.status === 400) {
        setErrors(res.json);
      } else {
        console.error('Unknown response', res);
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [data, id]);

  const handleClose = useCallback(() => {
    setErrorMessage(null);
    setValidationErrors([]);
    onClose();
  }, [setErrorMessage, setValidationErrors, onClose]);

  useEffect(() => {
    if (pointRequest) {
      const evidences = pointRequest.evidences.map((evidence) => ({
        ...JSON.parse(evidence.file_data),
        id: evidence.id,
        fileUrl: evidence.file_url
      }));

      setData({ ...pointRequest, evidences });
    }
  }, [pointRequest]);

  const isVocationalPointCurrency =
    data.type === 'VocationalPointRequest' || type === 'PointsVocational';

  return (
    <DefaultModal
      wide
      isOpened={isOpened}
      isLoading={isLoading}
      onClose={handleClose}
      title={`Edit ${type_label}`}
      action={
        <Grid container justifyContent="flex-end">
          <DefaultButton
            className={classes.editButton}
            color="secondary"
            onClick={handleSubmit}
            size="small"
            variant="contained"
          >
            Save
          </DefaultButton>
        </Grid>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
        <Grid item xs={4}>
          <WaveTextField
            label="Points"
            onChange={handleInputChange}
            {...makeFieldProps('points', data.points)}
          />
        </Grid>
        <Grid item xs={4}>
          {type === 'ReaPointRequest' && (
            <WaveSelectField
              emptyMenuItem
              label="Domain"
              menuItems={domainOptions}
              onChange={handleInputChange}
              {...makeFieldProps('domain_id', data.domain_id, 'domain')}
            />
          )}
          {type === 'VocationalPointRequest' && (
            <WaveSelectField
              label="Activity Type"
              menuItems={vocationalActivitiesOptions}
              onChange={handleInputChange}
              {...makeFieldProps(
                'vocational_activity_id',
                data.vocational_activity_id,
                'vocational_activity'
              )}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <WaveTextField
            label="Name of Activity"
            onChange={handleInputChange}
            {...makeFieldProps('activity_name', data.activity_name)}
          />
        </Grid>
        <Grid item xs={4}>
          <WaveDatePicker
            className={classes.ieFix}
            format={Chisholm.rangeDate}
            label="Date of Activity"
            onChange={handleDateChange('activity_date')}
            {...makeFieldProps('activity_date', data.activity_date)}
          />
        </Grid>
        <Grid item xs={4}>
          <WaveTextField
            label="Duration of Activity (number)"
            onChange={handleInputChange}
            {...makeFieldProps('activity_duration', data.activity_duration)}
          />
        </Grid>
        <Grid item xs={4}>
          <WaveSelectField
            label="Interval of Activity"
            menuItems={activityIntervalOptions}
            onChange={handleInputChange}
            {...makeFieldProps('activity_interval', data.activity_interval)}
          />
        </Grid>
        <Grid item xs={12}>
          {isVocationalPointCurrency && vocationalCurrencyUsesUnits ? (
            <VcmUnitPicker
              label="Related to Units"
              placeholder="Search by unit code"
              vcmUnits={vcmUnits}
              {...makeFieldProps('unit_codes', data.unit_codes)}
              onChange={handleArrayInputChange('unit_codes')}
            />
          ) : (
            <WaveTextArea
              label={
                isVocationalPointCurrency
                  ? 'Related to Units/s'
                  : 'Related to Course/s'
              }
              maxLength={1000}
              rows={4}
              onChange={handleInputChange}
              placeholder={
                isVocationalPointCurrency
                  ? 'Please list unit code/s and title/s with which this specific currency activity is relevant to.'
                  : 'Please list course title/s and course code/s.  In addition, list unit title/s and unit code/s of particular relevance to this activity.'
              }
              {...makeFieldProps('related_to_units', data.related_to_units)}
            />
          )}
        </Grid>
        <Grid item xs={6}>
          <WaveTextArea
            label="Inform Teaching and Assessment Practice"
            maxLength={1000}
            rows={4}
            onChange={handleInputChange}
            placeholder="Describe what you did, discussed or learnt in terms of new skills and knowledge, and how this will inform your teaching and assessment practices?"
            {...makeFieldProps(
              'inform_teaching_practice',
              data.inform_teaching_practice
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <WaveTextArea
            label="Student Benefit"
            maxLength={1000}
            rows={4}
            onChange={handleInputChange}
            placeholder="How will this activity impact/benefit your students?"
            {...makeFieldProps('student_benefit', data.student_benefit)}
          />
        </Grid>
        {type === 'ReaPointRequest' && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            justifyContent="center"
          >
            <DefaultCheckbox
              label="Learning Journey Equivalent"
              checkboxValue={true}
              unCheckValue={false}
              onChange={handleInputChange}
              {...makeFieldProps(
                'learning_journey_equivalent',
                data.learning_journey_equivalent
              )}
            />
          </Grid>
        )}
        {pointsFileAttachmentEnabled && (
          <Grid item xs={12}>
            <WaveMultipleFileUploader
              label="Attach Evidence"
              accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              maxFileSize={MAX_FILE_SIZE}
              onPush={handlePush}
              onReplace={handleReplace}
              onRemove={handleRemove}
              {...makeFieldProps('evidences', data.evidences)}
            />
          </Grid>
        )}
      </Grid>
    </DefaultModal>
  );
};

export default withStyles(EditModal, modalStyles, { withTheme: true });
